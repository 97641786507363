<!--
 * @Author: John
 * @Date: 2022-02-22 14:05:50
 * @LastEditTime: 2022-05-10 11:11:12
 * @LastEditors: John
 * @Description: 关于我们
 * @FilePath: \jjms_ui\src\views\site\about.vue
 * @Copyright John
-->
<template>
    <div style="padding: 0 300px;">
        <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="我的故事" name="0">
                <div class="article">
                    <p>
                        本人是一名在IT行业工作10余年的普通程序员，目前在一家创业公司做技术主管，本人热爱技术，同时一直在致力于如何用技术更好的服务生活！
                    </p>
                    <p>
                        我接触理财比较早，股票、黄金、基金，虚拟货币，甚至P2P理财，除了期货没碰过，其他都玩过。 <br>
                        这些年下来，理财不仅没有给我的家庭财务带来什么改观，反而可以用命途多舛来形容，P2P平台跑路亏了几万，股票亏了一万多，基金目前收益还不错，现在基本除了在定投基金别的都停了。
                    </p>
                    <p>
                        虽然这些年投资理财没赚到什么钱，但我悟出来一个道理：<b>投资理财就是一个逆人性操作的东西！(别人贪婪的时候我恐惧，别人恐惧的时候我贪婪)</b>，
                        话虽如此，但人性这个东西真不是我们说控制就能控制的。
                        讲个我的亲身经历，2018年由于工作原因我接触了虚拟货币，当时公司很多人都在炒币，我观望了一个月后，终于禁不住诱惑也下水了，
                        当时陆陆续续投了两三万进去，每天上班第一件事就是打开火币看行情，当时有同事买了一些不知名的空气币，一下赚了两三倍！说不眼红那是假的，但我不敢，还是坚持只买主流的BTC、BCH，期间有赚有赔
                        还算不错，这样一直到了18年年底，炒过币的朋友们可能都知道18年年底币圈史无前例的大地震，带头大哥BTC（比特币）都一度从两万美金跌到了不到三千美金！什么概念？好比你刚花100w买辆豪车，转手卖的时候人家告诉你最多值10w！
                        <br>
                        当时整个币圈情绪都很低落，
                        媒体上都在渲染中国政府要全面禁止虚拟货币交易，甚至很多大佬预测这次币圈可能要清零了，我刚开始跌到四千多的时候还加了一次仓，到两千多的时候感觉已经没戏了，就盼着啥时候能稍微涨涨就割肉下车，不玩了。
                        后来过了半年，涨了一些，我就割肉出来了，从此远离币圈，金盆洗手。直到前几天和以前的朋友聊到虚拟货币了，看了一下BTC的价格，乖乖，涨到四万美金了！ 
                        我跟朋友打趣，如果18年底别人吓破胆的时候，咱俩车子房子一把梭，现在估计早就财富自由了，哈哈！ <br>
                        我的这个经历相信很多股市里的散户并不陌生，这就是市场，如果你想赚钱就得尽量让自己变的绝对理智，只有抛去人性的弱点，才能更加理性的做决策，在动荡的市场中分得一杯羹。
                    </p>
                    <p>
                        认识到这个问题后，我开始定投基金，而且是被动类型的指数基金，为啥选被动型基金，因为我喜欢被动，哈哈开个玩笑，真正的原因是它不需要人为决策，完全跟着市场走，比如常见的宽基指数：沪深300，基本只要大盘涨，你就能赚钱，大盘跌你就亏钱，
                        根本不需要你花时间去挑选股票，分析年报，非常适合没有太多时间和精力去了解市场行情的上班族（就是我，嘿嘿），而且我的偶像股神巴菲特说过一句话：“<b>通过定期投资指数基金，一个什么都不懂的投资者通常都能打败大部分的专业基金经理</b>”，
                        这句话犹如一针强心剂，更加坚定了我定投指数基金的决心。
                        很长一段时间我把其他理财都停掉了，就坚持每周定投我挑选出来的几个指数基金，每周投200，无论市场涨跌，风雨无阻，收益大概有3%左右，而且我一点都不着急，因为我知道随着时间的推移，随着我累计定投
                        的不断增加，在复利的效应下，我的收益会越来越高。<br>
                        本来如果没有什么意外，我就打算一直这么定投下去了，直到有一天...
                    </p>
                    <p>
                        我记不清在什么地方看到一个别人分享的基金定投策略，说是能让定投增加放大镜效应，大概意思就是说“不要傻傻的投，而是根据市场行情动态微调每次定投的金额，这样可以让你在价位低的时候买到更多的筹码，
                        价位高的时候减少买入，甚至不买，或者卖出”。并且作者还按以往几年的数据模拟对比了一下两种定投方式的差别，果然带放大镜效果的收益要好于固定金额定投。
                        然后我就开始实操，我是用支付宝定投基金的，定投频率是每周四，然后一到周四，我就开始拿计算器累计上周四到这周三我的收益情况，然后用我计划定投的200元减去上周收益，如果上周收益是-100，
                        那按照规则我本周就应该定投200-(-100)=300元，这样可以让我在价位较低时买到更多筹码（抄底）；如果上周收益是100，按照规则我本周应该定投200-100=100元，这样在价位较高时，我可以减少定投金额（不做接盘侠）；
                        如果上周收益是300，按照规则本周应该定投200-300=-100，也就是说我不仅不应该定投，还应该卖出100（高位套现，落袋为安），规则虽然简单，但确实神奇，就这么办！
                    </p>
                    <p>
                        执行了一段时间之后我发现问题了，这种方式虽然好，但也有缺点，每次计算上期收益太麻烦，我定投了两支基金，周期是每周，这样我需要手动累加两个7天的数据；如果我是按月定投呢？那就得手动累加两个30天的
                        收益，我的天，饶了我吧！<br>
                        转念一想，我是干什么的，咱就是吃技术这碗饭的，写程序啥的不在话下，这玩意儿我能不能写个程序自动来管理呢？我只要设定好定投计划，到期自动提醒我，并且程序自动计算好上期的收益，直接告诉我本大爷这期应该投多少，这多好，嘿嘿... <br>
                        说干就干，买服务器，买域名，整整一周时间，茶不思饭不香，晚上都顾不上睡觉，热火朝天的怼了一周，本系统的核心功能出来了，然后我就开始作为第一个用户试用，期间发现问题、修复问题，不断打磨，现在自我感觉系统体验良好（反正我是这么认为的，哈哈）
                        <br>
                    </p>
                    <p>
                        以上就是本平台的开发背景了，技术人，就是这么单纯，哈哈！
                    </p>
                    <p>
                        为什么对外开放? <br>
                        首先声明本系统我打算完全免费开放给大家使用，没打算靠它赚钱（当然为了能够持续健康的运营下去，大家可以根据自己的情况适当捐赠一些，毕竟买服务器也是要花钱的，哭...），更多的还是想让自己花心血做出来的东西能够帮助到更多人，让大家能在忙碌的工作能够更轻松的享受定投指数基金带来的财富增值！
                    </p>
                </div>
            </el-collapse-item>
            <el-collapse-item name="1">
                <template slot="title">
                    <span class="title">定投金额计算原理</span>
                </template>
                简单来说，平台会自动计算上期的收益，到定投日，根据上期定投收益情况和本期设定的预计定投金额来决定本期推荐定投金额。 <br>
                <el-table border :data="ylTableData" :cell-style="ylCellStyle">
                     <el-table-column
                        prop="yj"
                        label="预计定投金额"
                        width="180">
                        </el-table-column>
                        <el-table-column
                        prop="last"
                        label="上期收益金额"
                        width="180">
                        </el-table-column>
                        <el-table-column
                        prop="bq"
                        label="本期推荐金额">
                        </el-table-column>
                </el-table> 
                <br>
                <b>轻松实现低位多买，高位少买或卖出~</b>
            </el-collapse-item>
            <el-collapse-item title="核心功能" name="2">
                <ul>
                    <li>智能提醒。系统支持按月、按周设置定投计划，到定投日自动发送邮件提醒！</li>
                    <li>定投金额智能计算。系统自动按照上期收益情况智能给出建议，只需按系统建议操作就好，省心的同时收益还能最大化！</li>
                    <li>强大的收益分析报告。系统提供便捷的收益统计功能，可方便的查看自己每期、每月、每年的收益情况！</li>
                    <li>规避人性的弱点。完全程序化操作，避免由于主观决策影响定投收益。</li>
                </ul>
            </el-collapse-item>
            <el-collapse-item title="联系我" name="3">
                <p>
                    为了更好为大家答疑解惑，方便各位基友交流，请大家扫描下方二维码，添加官方微信公众号
                </p>
                <img style="height:150px" src="@/assets/img/qr_code.jpg" alt="公众号">
                <p>
                    如果大家有好的功能建议，欢迎大家到公众号留言，大家一起为我们的工具添砖加瓦，让它越来越好用！
                </p>
            </el-collapse-item>
            <el-collapse-item title="关于捐赠" name="4">
                <p>
                    本服务完全免费提供，服务运营所需开支由本人承担，当然为了让平台能持续运营下去，大家也可以扫描下方的二维码进行捐赠（备注登录账号，定期会公布捐赠列表哈）<br>
                    再次声明：捐赠完全自愿，不捐赠完全不影响使用，捐赠了也不会有什么特权，哈哈！
                </p>
                <div class="jz-box">
                    <img src="@/assets/img/alipay_qrcode.jpg" alt="支付宝收款码">
                    <img src="@/assets/img/wx_qrcode.jpg" alt="微信收款码">
                </div>
            </el-collapse-item>
            <el-collapse-item title="免责声明" name="5">
                <ul>
                    <li>本工具定位是基金定投辅助工具，并非基金代销平台，无法直接完成基金交易，也无需登录股票交易账号！</li>
                    <li>本工具建议的定投金额根据用户上期收益按照固定规则计算得出，仅作为决策参考，由此带来的任何损失，本平台概不负责！</li>
                    <li>时刻牢记：<span style="color:red;">市场有风险，投资需谨慎！</span></li>
                </ul>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>
<style scoped>
.article > p {
    text-indent: 2em;
}
.jz-box > img {
    height: 250px;
}
.jz-box > img:nth-child(2) {
    margin-left: 100px;
}
.title {
    font-weight: bold; 
    font-size: 14px;
    color: #409eff;
    }
</style>
<script>
export default {
    name: 'about',
    data() {
        return {
            activeNames: ['1', '2','3','5'],
            ylTableData: [
                {yj: 200, last: 54, bq: 146},
                {yj: 200, last: -98, bq: 298},
                {yj: 200, last: 310, bq: -110}
            ]
        }
    },
    methods: {
        handleChange(val) {
            console.log(val)
        },
        ylCellStyle({row, columnIndex}){
            if(columnIndex === 1) {
                let val = row.last;
                if(val < 0) {
                    return "color: green";
                } else if(val > 0) {
                    return "color: red";
                } 
            } else if(columnIndex === 2) {
                if(row.bq < row.yj) {
                    return "color: green";
                } else if(row.bq > row.yj) {
                    return "color: red";
                }
            }
        }
    }
}
</script>
